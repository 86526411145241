import React, { useEffect } from 'react';

/**
 * Helper to add scripts to the page.
 * @param {string} src The source path for the script to insert.
 * @param {string} id The unique identifier for the script element to insert.
 * @param {HTMLElement} parentElement The DOM element to insert the script into.
 */
const insertScript = (src, id, parentElement) => {
  const script = window.document.createElement('script');
  script.async = true;
  script.src = src;
  script.id = id;
  parentElement.appendChild(script);
  return script;
};

/**
 * Helper to remove scripts from the page.
 * @param {string} id The unique identifier for the script element to remove.
 * @param {HTMLElement} parentElement The DOM element to remove the script from
 */
const removeScript = (id, parentElement) => {
  const script = window.document.getElementById(id);
  if (script) {
    parentElement.removeChild(script);
  }
};


const Commento = ({ id }) => {
  useEffect(() => {
    // If there's no window there's nothing to do for us
    if (!window) {
      return;
    }
    const { document } = window;
    // In case our #commento container exists we can add our commento script
    if (document.getElementById('commento')) {
      insertScript('https://comments.while-false.de/js/commento.js', 'commento-script', document.body);
    }
    // Cleanup; remove the script from the page
    return () => removeScript('commento-script', document.body);
  }, [id]);
  return <div id="commento" />;
};

export default Commento;
